<template>
  <div class="attractions">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        :md="paramID ? 6 : 12"
        v-if="!$vuetify.breakpoint.mobile || !paramID"
      >
        <list-items
          :loading="loading"
          @paginationHandler="fetchList"
          :listItems="listItems"
          :total="total"
          :updateKeyPage="updateKeyPage"
          :selectedPage.sync="page"
          :pageSize="pageSize"
          @searchAttraction="searchAttraction"
          :canAddAttraction="false"
          :md="paramID ? 6 : 3"
          @itemClicked="itemClicked"
        />
      </v-col>
      <v-col cols="12" :md="6">
        <item-body
          v-if="paramID"
          :itemID="itemID"
          @reloadData="reloadData"
          @collapse="collapse"
          :actions="['edit', 'restore']"
          @restoreHandler="restoreAttraction"
        ></item-body>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListItems from "@/components/miniguides/ListItems";
import ItemBody from "@/components/miniguides/ItemBody";
export default {
  components: {
    ListItems,
    ItemBody
  },
  data: () => ({
    paramID: "",
    listItems: [],
    itemID: undefined,
    total: 0,
    loading: false,
    keyUpdated: false,
    updateKeyPage: false,
    pageSize: 30,
    page: 1
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    reloadData() {
      this.fetchList();
      this.fetchItemByID();
    },
    async searchAttraction(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.listItems.length > 0) {
          this.paramID = this.listItems[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.itemID = undefined;
        }
      }
    },
    async fetchList() {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      let response = await this.$axios.get(
        `/deleted/attraction?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }&pageSize=${this.pageSize}&pageNumber=${this.page}&sort=0`,
        { cancelToken: this.source.token }
      );
      if (response && response.data) {
        this.listItems = response.data.data;
        this.total = response.data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/miniguide/${this.paramID}`);
        if (data) {
          this.itemID = data;
        }
      }
    },
    collapse() {
      this.$router.push("/deleted-attractions");
      this.paramID = undefined;
    },
    itemClicked(item) {
      this.$router.push(`/deleted-attractions/${item._id}`);
    },
    async restoreAttraction(item) {
      let { data } = await this.$axios.put(`miniguide/edit/new/${item._id}`, {
        deleted: false
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Restored Succesfully",
          color: `success`
        });
        this.collapse();
        this.reloadData();
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: `error`
        });
      }
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.attractions {
  background: white;
  height: 100vh;
  overflow: hidden;
}
</style>
